<template>
  <div class="drop-down-setting">
    <v-row class="mx-auto mt-auto mb-auto">
      <v-col class="px-0 pt-0 pb-0 mx-auto mt-auto mb-auto">
        <div class="box-user-details-style">
          <span class="name-user-style" v-if="getUserData && getUserData.name">
            {{ getUserData.name }}</span
          >
        </div>
      </v-col>
      <v-col class="px-0 pt-0 pb-0">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          origin="top right"
          :nudge-width="20"
          content-class="popover-menu-setting"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <div class="profile-pic" :style="computedImageUser">
                <img
                  v-if="getUserData && getUserData.photo == null"
                  src="../../assets/user-empty.jpg"
                  width="200"
                />
                <img v-else :src="getUserData.photo" width="200" />
              </div>
            </v-btn>
          </template>
          <v-card>
            <v-list class="list-setting">
              <!-- <v-list-item class="custom-hover-effect">
                <v-list-item-action>
                  <router-link to="/setting">
                    Paramètres
                  </router-link>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider> -->
              <v-list-item class="custom-hover-effect">
                <v-list-item-action @click="deconnexion">
                  Se déconnecter
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      menu: false
    };
  },
  methods: {
    ...mapActions(['logOut']),
    deconnexion() {
      this.logOut();
    },
    routerLink() {
      this.$router.push('/setting');
      this.menu = false;
    }
  },
  computed: {
    ...mapGetters([
      'getUserData',
      'getErrorExportAllFactureCdg',
      'getLoadingExportAllFactureCdg',
      'getSettingFilialesTh',
      'getLoadingSettingFilialeTh',
      'isSuperAdminGetter'
    ]),

    computedImageUser() {
      if (this.getUserData && this.getUserData.image) {
        return {
          'background-image': 'url(' + `${this.getUserData.image}` + ')'
        };
      } else {
        return { 'background-color': '#F2F2F2' };
      }
    }
  }
};
</script>
<style lang="scss" scoped>
a {
  color: #2f2f2f;
  &.router-link-exact-active {
    color: #2f2f2f;
  }
}
@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-hover-effect {
  &:hover {
    background-color: #f1f1f1;
  }
}
$circleSize: 40px;
$radius: 100px;
$shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
$fontColor: rgb(250, 250, 250);

.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  @include object-center;
  position: relative;
  transition: all 0.3s ease;

  input {
    display: none;
  }

  img {
    position: absolute;
    object-fit: cover;
    width: $circleSize;
    height: $circleSize;
    box-shadow: $shadow;
    border-radius: $radius;
    z-index: 0;
  }

  .-label {
    cursor: pointer;
    height: $circleSize;
    width: $circleSize;
  }

  &:hover {
    .-label {
      @include object-center;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 10000;
      color: $fontColor;
      transition: background-color 0.2s ease-in-out;
      border-radius: $radius;
      margin-bottom: 0;
    }
  }

  span {
    display: inline-flex;
    padding: 0.2em;
    height: 2em;
  }
}
</style>
<style lang="scss">
.avatar-user {
  width: 40px;
  height: 40px;
}
.drop-down-setting {
  margin: 5px 12px 0 10px;
}
.list-setting {
  cursor: pointer;
  box-shadow: none !important;
}
.icon-user-style {
  background-color: #fff !important;
  width: 42px;
  height: 42px;
  overflow: hidden;
  background-size: contain;
  background-position: 50%;
  border-radius: 50px;
  background-repeat: no-repeat;
  .icon {
    color: white;
    height: 30px;
    font-size: 25px;
  }
}
.box-user-details-style {
  display: grid;
  font-family: 'Montserrat', sans-serif;
  text-align: end;
  margin: auto;
  margin-right: 12px;
  width: 110px;
  // padding-top: 7px;
  .name-user-style {
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 14px;
    color: #2f2f2f !important;
  }
  .role-user-style {
    font-size: 10px;
    font-weight: 400;
    color: #aaaaaa;
  }
}
button.btn-setting.v-btn.v-btn--plain.theme--light.v-size--default {
  background: #fff;
  padding-top: 14px;
  box-shadow: none;
}
</style>
